import React, { useState, useEffect } from 'react';
import { makeStyles, Paper, Button, Typography, Box } from '@material-ui/core';
import clsx from 'clsx';
import { ReactComponent as TooltipIcon } from '@assets/icons/ic_tooltip.svg';
import { ReactComponent as CloseIcon } from '@assets/icons/ic_x.svg';
import { OpenModalButton } from '@src/components/Modal/OpenModalButton';
import { useUser } from '@src/hooks/useUser';

const ContactUsModal = React.lazy(() =>
  import('@src/components/Modal/ContactUs')
);

const useStyles = makeStyles(() => ({
  paper: {
    padding: '12px 16px',
    display: 'flex',
    alignItems: 'center',
    gap: 8,
    background: 'rgba(235, 245, 235, 1)',
    position: 'relative',
    top: 0,
    left: 0,
  },
  text: {
    lineHeight: '18px',
    letterSpacing: '0.24px',
  },
  button: {
    textDecoration: 'underline',
    marginLeft: 'auto',
    fontWeight: 400,
    '&:hover': {
      textDecoration: 'underline',
    },
  },
  icon: {
    '& rect': {
      fill: 'rgba(129, 192, 131, 1)',
    },
    '& circle': {
      stroke: 'rgba(129, 192, 131, 1)',
    },
    '& circle:last-child': {
      fill: 'rgba(129, 192, 131, 1)',
    },
  },
  closeIcon: {
    cursor: 'pointer',
    '& path': {
      fill: 'black',
    },
  },
}));

const PWABanner = () => {
  const { paper, text, button, icon, closeIcon } = useStyles();
  const [bannerDismissed, setBannerDismissed] = useState(false);
  const { data: user } = useUser();

  const handleDismiss = () => {
    const clientId = user?.clientId || '';
    if (!clientId) return;
    const key = `pwaBannerDismissed-${clientId}`;
    localStorage.setItem(key, true);
    setBannerDismissed(true);
  };

  useEffect(() => {
    const clientId = user?.clientId || '';
    if (!clientId) return;
    const key = `pwaBannerDismissed-${clientId}`;
    const dismissed = localStorage.getItem(key);
    if (dismissed) {
      setBannerDismissed(true);
    }
  }, [user]);

  if (bannerDismissed || !user) {
    return null;
  }

  return (
    <Paper
      className={clsx(paper, 'box-shadow-2')}
      data-testid="verify-email"
      id="verify-email"
    >
      <TooltipIcon className={icon} />
      <Box>
        <Typography variant="h4" component="h4" className={clsx('h4', text)}>
          Web-based scanning now available!
        </Typography>
        <Typography
          variant="body1"
          component="p"
          className={clsx('p1', 'extra-dark-grey', text)}
        >
          You can now set your QR code to direct all users to the web app. Read
          more about it{' '}
          <a
            target="_blank"
            href="https://mailchi.mp/truetoform/web-app-release"
            rel="noreferrer"
          >
            here
          </a>
          , and get in touch with us to request the switch.
        </Typography>
      </Box>
      <OpenModalButton
        component={ContactUsModal}
        props={{
          subject: `Request to switch to web-based scanning (${user?.clientId})`,
          message:
            'I would like to switch my QR code to direct all users to the web app instead of the iOS app.',
        }}
      >
        <Button variant="text" color="primary" className={clsx(button, 'h4')}>
          Contact us
        </Button>
      </OpenModalButton>
      <CloseIcon onClick={handleDismiss} className={closeIcon} />
    </Paper>
  );
};

export default PWABanner;
